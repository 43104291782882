<template>
  <div class="dashboard">

    <b-card class="card_scroller" no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header tabs>
          <b-nav-item :to="{name:'dashboard'}" exact-active-class="active">Overview</b-nav-item>
          <b-nav-item :to="{name:'book'}" active-class="active">Books</b-nav-item>
          <b-nav-item active-class="active">Users</b-nav-item>
          <b-nav-item :to="{name:'school'}" active-class="active">Schools</b-nav-item>
        </b-nav>
      </b-card-header>
      <b-card-body class="card_body">
        <router-view></router-view>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
export default { name: 'BackendDashboard' };
</script>

<style lang="stylus" scoped>

.dashboard
  position relative
  .card_body
    height 100%

</style>
